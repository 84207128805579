/* ConPaper.module.css */
.paper {
  position: relative;
  margin-bottom: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.boxIn {
  position: relative;
  padding: 48px;
  margin-top: 80px;
}

.typography_1 {
  color: white;
}

.typography_2 {
  color: white;
  margin-bottom: 16px;
}
