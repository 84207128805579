.box {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;
  }

  .boxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  
  .boxItemDynamic {
    margin-bottom: 8px;
    align-items: center;
  }
  
  .numberField {
    margin-top: 20px;
  }