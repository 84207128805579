/* ConHeader.module.css */
.box {
  margin: 16px;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: "100vw" 
}


.container {
  margin-left: "395px" ! important;
  margin-right: "50px";
  left: "300px"
}

.toolbar {
  width: "100%";
}

.typography_h5 {
  flex-grow: 1;
  padding-left: 24px;
}

.icon_button {
  font-size: 40px !important;
  color: white;
}

.typography {
  font-weight: 300;
  font-size: 14px;
  color: white;
}

.appbar {
  z-index: 1049 !important;
}
