/* conDataInput.module.css */
.form_control {
  width: 99%;
  margin-left: 5px !important;
  margin-top: 20px !important;
  padding-bottom: 20px !important;
  border: 1px solid #ccc !important;
  border-radius: 8px;
}

.form_label {
  margin-left: 25px;
}

.box {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}
